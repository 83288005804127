import React, { useEffect, useState } from "react"
import { useSearchParams, useLocation, Link } from "react-router-dom"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

import axios from "../../axios"
import ProductRowCard from "../ProductRowCard"
import NoSearchResultsBlock from "./NoSearchResultsBlock"
import SearchFilterBlock, { defaultSearchFilter } from "./SearchFilterBlock"
import { skeletonWrapperArray } from "../../mui/SkeletonWrapper"

/**
 * Wrapper for the search products
 */
function SearchProductWrapper({
    baseSearchFilter = defaultSearchFilter,
    showFilter = true,
    onResultsUpdated = undefined,
    subTitle = undefined,
    searchTitle = undefined,
    isParentLoading = false,
}) {
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(isParentLoading)
    const [searchResults, setSearchResults] = useState(null)
    const [pageAmt, setPageAmt] = useState(null)

    const [searchFilterOptions, setSearchFilterOptions] = useState(null)
    const [searchFilter, setSearchFilter] = useState(null)

    /**
     * Finds the search results
     */
    const onUpdateSearchResults = async (abortController) => {
        if (!searchFilter) return

        setIsLoading(true)
        try {
            let pageNum = parseInt(searchParams.get("page"))
            if (isNaN(pageNum)) pageNum = 1

            const res = await axios.post(`/api/search/`, { ...searchFilter, pageNum }, { signal: abortController?.signal })

            setSearchResults(res.data?.products)
            setPageAmt(res.data?.pageAmt)
            setSearchFilterOptions(res.data?.filters)

            if (onResultsUpdated) onResultsUpdated(res.data?.products?.length ?? 0)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Search query changed
     */
    useEffect(() => {
        if (baseSearchFilter) {
            setSearchFilter({ ...baseSearchFilter })
        }
    }, [
        baseSearchFilter?.searchField,
        baseSearchFilter?.categoryId,
        baseSearchFilter?.subCategoryUuid,
        baseSearchFilter?.brandUuid,
        baseSearchFilter?.rangeUuid,
        baseSearchFilter?.sonSaleOnly,
        baseSearchFilter?.sortBy,
        baseSearchFilter?.properties,
        baseSearchFilter?.newProductsOnly,
    ])

    /**
     * Items changed (not covered from)
     */
    useEffect(() => {
        if (isParentLoading) return
        const abortController = new AbortController()

        onUpdateSearchResults(abortController)

        return () => abortController.abort()
    }, [searchFilter, searchParams, isParentLoading])

    return (
        <>
            <div className='flex-row align-items-center padding-bottom-8'>
                <SearchTitle searchTitle={searchTitle} subTitle={subTitle} />
                <SearchFilterBlock
                    showFilter={showFilter}
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    searchFilterOptions={searchFilterOptions}
                    baseSearchFilter={baseSearchFilter}
                />
            </div>

            <SearchPageNumbers pageAmt={pageAmt} useScrollIntoView={false} />

            {isLoading || searchResults?.length > 0 ? (
                <div className='product-section-wrapper' role='list' itemScope itemType='https://schema.org/ItemList'>
                    {isLoading
                        ? skeletonWrapperArray({
                              count: 10,
                              variant: "rounded",
                              width: "100%",
                              height: 300,
                              callback: (skeleton) => <div className='product-card-wrapper width-100'>{skeleton}</div>,
                          })
                        : searchResults?.map((p, index) => {
                              return (
                                  <ProductRowCard
                                      uuid={p.uuid}
                                      key={p.uuid}
                                      name={p.name}
                                      index={index}
                                      onSale={p.onSale}
                                      salePrice={p.salePrice}
                                      price={p.price}
                                      variantName={p.variantName}
                                      tags={p.tags}
                                      thumbnailUrl={p.thumbnailUrl[0]}
                                      secondThumbnailUrl={p.thumbnailUrl[1]}
                                      brandName={p.brandName}
                                      variantAmount={p.variantAmount}
                                      showCatPill={true}
                                      isCarouselItem={false}
                                      moreColorsAvailable={p.moreColorsAvailable ?? false}
                                      immediateDelivery={p.immediateDelivery ?? false}
                                  />
                              )
                          })}
                </div>
            ) : (
                <NoSearchResultsBlock subTitle={subTitle} searchTitle={searchTitle} />
            )}

            <SearchPageNumbers pageAmt={pageAmt} useScrollIntoView={true} />
        </>
    )
}

/**
 * A header section, wraps a h2 / p in a hgroup
 * @param {Object} props - The component props
 * @param {string} props.searchTitle - The title for this search
 * @param {string|undefined} props.subTitle - The subtitle to show before the search title
 */
function SearchTitle({ searchTitle, subTitle }) {
    return subTitle || searchTitle ? (
        <h1 className='search-page-text flex-100'>
            {subTitle ?? ""} <strong>{searchTitle ?? ""}</strong>
        </h1>
    ) : (
        <div className='search-page-text flex-100'>{/*push pill right*/}</div>
    )
}

/**
 * Shows the page numbers, the current page is picked and pushed to the ?page=n query param
 * @param {Object} props - The component props
 * @param {number} props.pageAmt - The number of total papges
 * @param {boolean} props.useScrollIntoView - if the ".scrollIntoView()" call should be issued after a page change to scroll to the top of the page
 */
function SearchPageNumbers({ pageAmt = 0, useScrollIntoView = true }) {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()

    if (pageAmt <= 1) return null

    const pageNoClick = (e, pageNo) => {
        e.preventDefault() // let the onclick do the work, so we dont loase the filters

        if (pageNo <= 0) pageNo = 1
        if (pageNo > pageAmt) pageNo = pageAmt

        // update the url
        if (pageNo > 1) searchParams.set("page", pageNo)
        else searchParams.delete("page")

        setSearchParams((currentValue) => {
            return { ...currentValue, page: pageNo }
        })

        if (useScrollIntoView) document.getElementById("s-page-top").scrollIntoView()
    }

    /**
     * Gets the page url with the correct page no
     */
    const getPageUri = (pageNo) => {
        if (pageNo <= 0) pageNo = 1
        else if (pageNo > pageAmt) pageNo = pageAmt

        const localSearchParams = new URLSearchParams(searchParams)

        // page one doesn't need the query param as its the defailt
        if (pageNo <= 1) localSearchParams.delete("page")
        else localSearchParams.set("page", pageNo)

        return `${location.pathname}?${localSearchParams}${location.hash}`
    }

    // + turns the result into a number
    let currentPage = parseInt(searchParams.get("page"))
    if (isNaN(currentPage)) currentPage = 1

    return (
        <>
            <div className='flex-row flex-gap-none padding-16'>
                <ul className='search-page-numbers' role='navigation' aria-label='Pagination Navigation'>
                    <li className='search-page-number'>
                        <Link
                            to={getPageUri(currentPage - 1)}
                            disabled={currentPage <= 1}
                            onClick={(e) => pageNoClick(e, currentPage - 1)}
                            aria-label='Goto Page Previous Page'
                        >
                            <NavigateBeforeIcon />
                            <span className='hidden'> Previous</span>
                        </Link>
                    </li>
                    {pageAmt &&
                        (() => {
                            const pages = []
                            for (let i = 1; i <= pageAmt; i++) {
                                const showMobile = i === 1 || i === pageAmt || Math.abs(i - currentPage) < 3
                                let className = "search-page-number"
                                if (currentPage === i) className += " spn-active"
                                if (!showMobile) className += " hidden-sm"

                                pages.push(
                                    <li key={i} className={className}>
                                        <Link
                                            to={getPageUri(i)}
                                            onClick={(e) => pageNoClick(e, i)}
                                            aria-current={currentPage === i}
                                            aria-label={`Goto Page ${i}`}
                                        >
                                            {i}
                                        </Link>
                                    </li>
                                )
                            }

                            return pages
                        })()}

                    <li className='search-page-number'>
                        <Link
                            to={getPageUri(currentPage + 1)}
                            disabled={currentPage >= pageAmt}
                            onClick={(e) => pageNoClick(e, currentPage + 1)}
                            aria-label='Goto Next Page'
                        >
                            <span className='hidden'>Next </span>
                            <NavigateNextIcon />
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default SearchProductWrapper
