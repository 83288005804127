import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Carousel from "react-multi-carousel"
import Select from "react-select"
import ReactGA from "react-ga4"
import { motion } from "framer-motion"

import SendIcon from "@mui/icons-material/Send"
import ZoomInIcon from "@mui/icons-material/ZoomIn"

import "./_styling/product.css"
import ImmediateDelivery from "../assets/immediate-delivery.svg"
import InterestFreeCreditPortrait from "../assets/interest-free-credit-portrait.png"

import axios from "../components/axios"
import Divider from "../components/Divider"
import MetaData, { metaDescription, metaTitle } from "../components/MetaData"
import Breadcrumbs from "../components/navigation/Breadcrumbs"
import ProductRow from "../components/page-components/ProductRow"
import RedDivider from "../components/RedDivider"
import { defaultKeywords } from "../components/constants"
import { urlSlug } from "../components/utility/UsefulFunctions"
import AdditionalInformation from "../components/page-components/product/AdditionalInformation"
import Dimensions from "../components/page-components/product/Dimensions"
import ProductDescription from "../components/page-components/ProductDescription"
import SectionHeading from "../components/page-components/SectionHeading"
import LoadingHome from "../components/LoadingHome"

import PopupDialog from "../components/mui/PopupDialog"
import IconButton from "../components/mui/IconButton"
import { getRouteName } from "../components/utility/RouteHelpers"
import { formatPrice } from "../components/utility/UsefulFunctions"

import NoProductImage from "../assets/no-image.png"
import { priceValidUntilDate } from "../components/utility/ConvertTime"
import useSiteData from "../hooks/useSiteData"

/**
 * Main product page
 */
const Product = () => {
    const navigate = useNavigate()
    const { showInterestFreeCredit } = useSiteData()
    const { productUuid, variantUuid } = useParams()

    const [product, setProduct] = useState(null)
    const [variantInfo, setVariantInfo] = useState(null)
    const [images, setImages] = useState(null)
    const [files, setFiles] = useState(null)

    const [selectedImageIndex, setSelectedImageIndex] = useState(0)

    const [relatedProducts, setRelatedProducts] = useState(null)
    const [variantSelection, setVariantSelection] = useState(null)
    const [variantOptions, setVariantOptions] = useState(null)

    const setProductImages = (mainImages, variantImages) => {
        const productImages = mainImages.concat(variantImages)
        if (productImages.length === 0) productImages.push(NoProductImage)
        setImages(productImages)
    }

    /**
     * Get the product data
     */
    const getProductData = async (abortController) => {
        try {
            const [resProduct, resRP] = await Promise.all([
                axios.get(`/api/product/${productUuid}`, { signal: abortController?.signal }),
                axios.get(`api/search/related-products/${productUuid}`, { signal: abortController?.signal }),
            ])

            if (resProduct.status === 200) {
                const gaProd = resProduct.data.product
                setProductImages(gaProd.imageUrls, resProduct.data.variant.imageUrls)

                setVariantInfo(resProduct.data.variant)
                setSelectedImageIndex(0)
                setProduct(gaProd)

                // Sort files by name
                gaProd.files.sort((a, z) => {
                    const nameA = a.name.toUpperCase()
                    const nameZ = z.name.toUpperCase()
                    if (nameA < nameZ) return -1
                    if (nameA > nameZ) return 1
                    return 0
                })

                setFiles(gaProd.files)
                setRelatedProducts(resRP.data)

                ReactGA.event("view_item", {
                    items: [
                        {
                            item_id: gaProd.uuid,
                            item_name: gaProd.name,
                            item_brand: gaProd.brand,
                            item_category: gaProd.category,
                            item_category2: gaProd.subCategory,
                        },
                    ],
                })
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Varient changed
     */
    const onVariantChange = async (uuid) => {
        const res = await axios.get(`/api/product/variant/${uuid}`)
        setVariantInfo(res.data)
        setProductImages(product.imageUrls, res.data.imageUrls)
        res.data.imageUrls[0] ? setSelectedImageIndex(product.imageUrls.length) : setSelectedImageIndex(0)
    }

    /**
     * Page Init
     */
    useEffect(() => {
        const abortController = new AbortController()
        if (productUuid && (!product || productUuid !== product?.uuid)) {
            getProductData(abortController)
        }
        return () => abortController.abort()
    }, [])

    /**
     * Page changed
     */
    useEffect(() => {
        const abortController = new AbortController()
        window.scrollTo(0, 0)
        getProductData(abortController)
        return () => abortController.abort()
    }, [productUuid])

    /**
     * Product data retrived
     */
    useEffect(() => {
        if (product && product.uuid === productUuid) {
            if (variantUuid)
                product.variantList.forEach((v) => {
                    if (v.uuid === variantUuid) onVariantChange(v.uuid)
                })
        }
        if (variantInfo) {
            setVariantOptions(product.variantList.map((vi) => ({ value: vi.uuid, label: vi.name })))
        }
    }, [product])

    /**
     * Get all the meta tags for the product
     */
    const getMetaTags = (product, variantInfo) => {
        const variantInfoTags = variantInfo?.metaTags?.split(" ") ?? []
        const productTags = product?.metaTags?.split(" ") ?? []
        return [product?.name, ...variantInfoTags, ...productTags, ...defaultKeywords].filter((n) => n)
    }

    const hasPrice = variantInfo?.price || variantInfo?.salePrice
    const sellPrice = hasPrice ? (variantInfo.salePrice ? variantInfo.salePrice : variantInfo.price) : 99999

    const productShowInterestFreeCredit = showInterestFreeCredit && product?.showInterestFreeCredit

    return (
        <>
            <MetaData
                description={metaDescription({ ...product, subTitle: variantInfo?.name })}
                title={metaTitle({ ...product, subTitle: variantInfo?.name }, false)}
                keywords={getMetaTags(product)}
                canonicalUrl={product ? `${process.env.REACT_APP_SITE_URI}product/${productUuid}/${getRouteName(product.name)}` : undefined}
            />
            <div className='product-page-wrapper' id='product-top'>
                {product ? (
                    <div className='product-wrapper flex-column flex-gap-8 h-product'>
                        <Breadcrumbs
                            type='PRODUCT'
                            product={{ uuid: productUuid, name: product.name }}
                            category={{ category: product.category, catRoute: product.catRoute }}
                            subCategory={{ category: product.subCategory, subCatRoute: product.subCatRoute }}
                        />
                        <div className='p-inner-wrapper' itemType='https://schema.org/Product' itemScope>
                            {/* product meta data */}
                            <meta itemProp='name' content={`${product.name} ${variantInfo ? variantInfo.name : ""}`} />
                            <meta itemProp='description' content={product.description} />

                            {images?.map((i) => (
                                <link key={i} itemProp='image' href={i} />
                            ))}
                            {/* product meta data */}

                            <motion.div key={product.uuid + "-img"} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75 }}>
                                <ProductCarousel product={product} images={images} selectedImageIndex={selectedImageIndex} />
                            </motion.div>
                            <div>
                                <motion.h1
                                    key={product.uuid + "0"}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className='p-name'
                                >
                                    {product.name} <span className='p-variant-name'>{variantInfo && variantInfo.name}</span>
                                </motion.h1>
                                {product.variantList.length > 1 ? (
                                    <>
                                        <motion.div
                                            key={product.uuid + "1"}
                                            initial={{ opacity: 0, y: 50 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.1 }}
                                            className='p-mobile-variants-wrapper'
                                        >
                                            <Select
                                                id='variation-select'
                                                value={variantSelection}
                                                className='contact-select'
                                                options={variantOptions}
                                                onChange={(e) => {
                                                    setVariantSelection(e)
                                                    navigate(`/product/${productUuid}/${getRouteName(product.name)}/${e.value}`)
                                                    onVariantChange(e.value)
                                                }}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isSelected ? "grey" : "#ce0715",
                                                    }),
                                                }}
                                            />
                                        </motion.div>
                                        <motion.div
                                            key={product.uuid + "2"}
                                            initial={{ opacity: 0, y: 50 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.1 }}
                                            className='p-variants-wrapper flex-row flex-wrap'
                                        >
                                            {product.variantList.map((v) => {
                                                return (
                                                    <Link
                                                        key={v.uuid}
                                                        to={`/product/${productUuid}/${getRouteName(product.name)}/${v.uuid}`}
                                                        className={v.uuid === variantInfo.uuid ? "p-variant p-variant-selected flex-row" : "p-variant flex-row"}
                                                        onClick={() => onVariantChange(v.uuid)}
                                                    >
                                                        {/*<div className='pv-image'><img src={v.thumbnailUrl} crossOrigin='anonymous' referrerPolicy="same-origin" alt={v.name} /></div>*/}
                                                        <p>{v.name}</p>
                                                    </Link>
                                                )
                                            })}
                                        </motion.div>
                                    </>
                                ) : null}
                                <RedDivider padding={16} />

                                <ProductDescription description={product.description} variantDescription={variantInfo.description} />
                                <motion.div
                                    key={product.uuid + "7"}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.3 }}
                                >
                                    <br />
                                    <div className='flex-row flex-spacebetween full-width flex-wrap'>
                                        {productShowInterestFreeCredit ? (
                                            <img
                                                src={InterestFreeCreditPortrait}
                                                alt='Interest free credit available!'
                                                className='img-responsive thumbnail cover'
                                            />
                                        ) : null}
                                        <div className='padding-8 flex-100' itemProp='offers' itemScope itemType='https://schema.org/Offer'>
                                            <meta itemProp='name' content={`${product?.name} ${variantInfo?.name}`} />
                                            <meta
                                                itemProp='availability'
                                                content={hasPrice ? "https://schema.org/InStock" : "https://schema.org/InStoreOnly"}
                                            />
                                            <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                                            <meta itemProp='priceCurrency' content='GBP' />
                                            <meta itemProp='price' content={sellPrice} />
                                            <meta
                                                itemProp='priceValidUntil'
                                                content={priceValidUntilDate(hasPrice, variantInfo?.price, variantInfo?.salePrice)}
                                            />

                                            {variantInfo.price ? (
                                                <>
                                                    {!variantInfo.salePrice ? (
                                                        <div className='flex-row-end-vertical flex-gap-16 standard-price'>
                                                            <div>
                                                                <p>
                                                                    <b>Price</b>
                                                                </p>
                                                                <p className='red-serif p-price'>{formatPrice(variantInfo.price)}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='flex-row-end-vertical flex-gap-16 sale-price'>
                                                            <div>
                                                                <p>
                                                                    <b>NOW</b>
                                                                </p>
                                                                <p className='red-serif p-price'>{formatPrice(variantInfo.salePrice)}</p>
                                                            </div>
                                                            <div className='flex-column flex-gap-none'>
                                                                <p>
                                                                    <b>Was</b>
                                                                </p>
                                                                <p className='red-serif'>{formatPrice(variantInfo.price)}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {variantInfo.onSale && <p>This item is currently on clearance.</p>}
                                                    <Link to='/find-us' className='red-serif cursor-pointer'>
                                                        See in store for more details.
                                                    </Link>
                                                </>
                                            )}
                                            {productShowInterestFreeCredit ? (
                                                <p className='p-description margin-top-8'>Interest free credit available!</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </motion.div>
                                {variantInfo.dimensions.width ? (
                                    <Dimensions product={product} variantInfo={variantInfo} files={files} />
                                ) : (
                                    <RedDivider padding={16} />
                                )}
                                {files && files.length > 0 && <AdditionalInformation product={product} variantInfo={variantInfo} files={files} />}

                                <motion.div
                                    key={product.uuid + "8"}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.4 }}
                                    className='flex-column flex-gap-16'
                                >
                                    <div className='flex-row flex-wrap flex-gap-16'>
                                        <div className='flex-row-center-vertical'>
                                            <Link
                                                to={`/brand/${product.brandUuid}/${urlSlug(product.brand)}`}
                                                itemType='https://schema.org/Brand'
                                                itemScope
                                                className='red-link flex-row-center-vertical'
                                            >
                                                <div className='brand-logo'>
                                                    <img
                                                        src={product.brandLogo}
                                                        crossOrigin='anonymous'
                                                        referrerPolicy='same-origin'
                                                        alt={product.brand}
                                                        itemProp='logo'
                                                    />
                                                </div>
                                                {product.brand}
                                                <meta itemProp='name' content={product.brand} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='flex-row flex-wrap flex-gap-16' itemType='https://schema.org/Category' itemScope>
                                        <meta itemProp='category' content={`${product.category} > ${product.subCategory}`} />
                                        <p>
                                            Category:{" "}
                                            <Link to={`/store/${product.catRoute}`} className='red-link'>
                                                {product.category}
                                            </Link>
                                        </p>
                                        <p>
                                            Sub-Category:{" "}
                                            <Link to={`/store/${product.catRoute}/${product.subCatRoute}`} className='red-link'>
                                                {product.subCategory}
                                            </Link>
                                        </p>
                                    </div>

                                    {/* PRODUCT PROPS BELOW*/}
                                    {product?.properties && product?.properties.length > 0 ? (
                                        <div className='flex-column flex-wrap flex-gap-16'>
                                            {product.properties.map((prop, index) => (
                                                <ProductProperties key={index} productProperty={prop} />
                                            ))}
                                        </div>
                                    ) : null}

                                    {product?.moreColorsAvailable && (
                                        <>
                                            <div className='flex-row flex-wrap flex-gap-16 margin-8 full-width' style={{ height: "50px" }}>
                                                <img
                                                    src='https://rsazure.blob.core.windows.net/rhi-images/other%20options%20available.svg'
                                                    alt='more options available'
                                                    className='img-responsive'
                                                    crossOrigin='anonymous'
                                                    referrerPolicy='same-origin'
                                                />
                                            </div>
                                        </>
                                    )}

                                    {product?.immediateDelivery && (
                                        <>
                                            <div className='flex-row flex-wrap flex-gap-16 margin-8 full-width' style={{ height: "50px" }}>
                                                <img
                                                    src={ImmediateDelivery}
                                                    crossOrigin='anonymous'
                                                    referrerPolicy='same-origin'
                                                    alt='immediate delivery'
                                                    className='img-responsive'
                                                />
                                            </div>
                                        </>
                                    )}

                                    {/* todo product.immediateDelivery */}
                                </motion.div>
                                {/* SOCIAL SHARE BUTTONS BELOW*/}
                                <br />
                                <motion.div>
                                    <Link className='button-contained full-width-sm' to={`/contact/${variantUuid ?? variantInfo?.uuid}`}>
                                        <SendIcon /> Contact us about this product
                                    </Link>
                                </motion.div>
                            </div>
                        </div>
                        <Divider padding={16} />
                        {relatedProducts && relatedProducts.length > 0 && (
                            <>
                                <SectionHeading category='Related Products' heading='Products related to this item.' subheading='' />
                                <ProductRow products={relatedProducts} />
                            </>
                        )}
                    </div>
                ) : (
                    <LoadingHome />
                )}
            </div>
        </>
    )
}

/**
 * Properties
 */
const ProductProperties = ({ productProperty }) => {
    const values = productProperty.value
    if (!values || values.length === 0) return null

    const formatter = new Intl.ListFormat("en-GB", { style: "long", type: "conjunction" })

    return (
        <p>
            {productProperty.label}: {formatter.format(values)}
        </p>
    )
}

/**
 * Product carousel
 */
const ProductCarousel = ({ product, images, selectedImageIndex = 0, className = "" }) => {
    const [isDragging, setIsDragging] = useState(false)
    const [popupImage, setPopupImage] = useState(null)

    const responsive = {
        desktop: {
            breakpoint: {
                max: Number.MAX_SAFE_INTEGER,
                min: 1024,
            },
            items: 1,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0,
            },
            items: 1,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464,
            },
            items: 1,
        },
    }

    return (
        <>
            <PopupDialog isOpen={popupImage !== null} title={product?.name} onClose={() => setPopupImage(null)}>
                <div className='product-carousel-popup'>
                    <img
                        src={popupImage}
                        crossOrigin='anonymous'
                        referrerPolicy='same-origin'
                        alt={product.name}
                        className='cursor-pointer'
                        loading='lazy'
                        draggable={false}
                    />
                </div>
            </PopupDialog>
            <Carousel
                containerClass={`product-carousel-container ${className}`}
                itemClass='product-carousel-item'
                customButtonGroup={<ProductCarouselButtonGroup images={images} productName={product?.name} selectedImageIndex={selectedImageIndex} />}
                arrows={true}
                //autoPlaySpeed={3000}
                autoPlay={false}
                centerMode={false}
                responsive={responsive}
                swipeable
                draggable
                infinite={false}
                pauseOnHover
                keyBoardControl
                beforeChange={() => setIsDragging(true)}
                afterChange={() => setIsDragging(false)}
            >
                {images.map((pi, i) => {
                    return (
                        <div key={i} className='p-image'>
                            <img
                                src={pi}
                                alt={product.name}
                                className='u-photo cursor-pointer'
                                loading='lazy'
                                draggable={false}
                                crossOrigin='anonymous'
                                referrerPolicy='same-origin'
                                onClick={(e) => {
                                    if (isDragging) {
                                        e.preventDefault()
                                    } else {
                                        setPopupImage(pi)
                                    }
                                }}
                            />
                            <div style={{ position: "absolute", bottom: 8, right: 8 }}>
                                <IconButton
                                    hintText='Click to open expanded view'
                                    size='small'
                                    onClick={(e) => {
                                        if (isDragging) {
                                            e.preventDefault()
                                        } else {
                                            setPopupImage(pi)
                                        }
                                    }}
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </>
    )
}

/**
 * Product carousel buttons
 */
const ProductCarouselButtonGroup = ({ goToSlide, carouselState, images, productName, selectedImageIndex = 0 }) => {
    const { currentSlide } = carouselState
    const onThumbnailClick = (index) => {
        goToSlide(index)
    }

    useEffect(() => {
        onThumbnailClick(selectedImageIndex)
    }, [selectedImageIndex, images])

    return (
        <div className='product-carousel-button-group flex-row flex-gap-8 flex-wrap'>
            {images?.map((pi, i) => {
                return (
                    <div key={i} className={currentSlide === i ? "p-mini-image selected-mini-img" : "p-mini-image"} onClick={() => onThumbnailClick(i)}>
                        <img
                            src={pi}
                            crossOrigin='anonymous'
                            referrerPolicy='same-origin'
                            alt={productName + " small"}
                            className='u-photo cover'
                            title='Click to view'
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default Product
