import React, { useEffect, useState } from "react"

import "./_styling/product.css"
import "./_styling/search.css"

import axios from "../components/axios"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import SearchProductWrapper from "../components/page-components/search/SearchProductWrapper"
import { defaultSearchFilter } from "../components/page-components/search/SearchFilterBlock"
import CategoryHeader from "../components/page-components/CategoryHeader"

/**
 * Clearance / Special Offers page
 */
const Clearance = () => {
    const [pageInfo, setPageInfo] = useState(null)
    const [searchFilter, _setSearchFilter] = useState({ ...defaultSearchFilter, onSaleOnly: true })

    /**
     * Load the page info
     */
    const getInitialData = async (abortController) => {
        try {
            const pageInfoRes = await axios.get("/api/category/pageinfo/clearance", { signal: abortController?.signal })
            setPageInfo(pageInfoRes.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getInitialData(abortController)
        return () => abortController.abort()
    }, [])

    return (
        <>
            <MetaData
                description={metaDescription({ ...pageInfo, name: "Special Offers" })}
                title={metaTitle({ ...pageInfo, name: "Special Offers" })}
                keywords={metaKeywords({ tags: ["clearance", "special offers", "offers"] })}
            />
            <div className='page-wrapper'>
                <CategoryHeader
                    heading={pageInfo?.pageTitle ?? "Special Offers"}
                    subheading={pageInfo && pageInfo.description}
                    backgroundImage={pageInfo && pageInfo.bannerUrl}
                />

                <div className='content-section-wrapper'>
                    <SearchProductWrapper
                        baseSearchFilter={searchFilter}
                        subTitle={pageInfo?.pageTitle ?? "Special Offers"}
                        //searchTitle={searchQuery}
                    />
                </div>
            </div>
        </>
    )
}

export default Clearance
